import * as reducers from "./reducers/index";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
const rootReducer = combineReducers(reducers);
const composeEnhancers =
	process.env.NODE_ENV === "development" ||
	process.env.REACT_APP_BUILD_ENV === "staging" ||
	true
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
		: // : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
		  compose;

const configureStore = () => {
	const store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(thunk))
	);

	if (process.env.NODE_ENV !== "production") {
		if (module.hot) {
			module.hot.accept("./reducers", () => {
				store.replaceReducer(rootReducer);
			});
		}
	}
	return store;
};

const store = configureStore();

export default store;
