export const SET_ORG = "SET_ORG";
export const SET_MOBILE = "SET_MOBILE";
export const SET_EMAIL  = "SET_EMAIL";
export const GENERATE_OTP= "GENERATE_OTP";
export const GENERATE_OTP_SUCCESS= 'GENERATE_OTP_SUCCESS';
export const SCREEN_REDIRECTION_STATUS="SCREEN_REDIRECTION_STATUS";
export const VERIFY_OTP_ERROR_CHANGE= "VERIFY_OTP_ERROR_CHANGE";
export const SET_OTP="SET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE"; 
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const SET_TEST_URL = "SET_TEST_URL";
export const SET_TEST_URL_FAIL= "SET_TEST_URL_FAIL";
export const SET_ORG_NAME = "SET_ORG_NAME";
export const SET_COUNTRY_DETAILS = "SET_COUNTRY_DETAILS"

// Token Manupulation
export const SET_STUDENT_SIDE_TOKEN = "SET_STUDENT_SIDE_TOKEN";
export const SET_TUTOR_SIDE_TOKEN = "SET_TUTOR_SIDE_TOKEN";


// Toaster
export const ADD_TOASTER = "ADD_TOASTER";
export const REMOVE_TOASTER = "REMOVE_TOASTER";
export const CLEAR_ALL = "CLEAR_ALL";

// Free Test Tutor
export const SET_FREE_TEST_LOADING= "SET_FREE_TEST_LOADING";
export const SET_FREE_TEST_STATE = "SET_FREE_TEST_STATE";
export const SET_TESTLIST = "SET_TESTLIST";
export const SET_FREE_TESTLIST = "SET_FREE_TESTLIST";
export const SET_TEST_DETAILS = "SET_TEST_DETAILS";
export const SET_DATE = "SET_DATE";
export const SET_WHATSAPP_DRAWER = "SET_WHATSAPP_DRAWER";

// Test Student Details

export const SET_TEST_STUDENT_DETAILS = "SET_TEST_STUDENT_DETAILS";
export const RESET_TEST_STUDENT_DETAILS = "RESET_TEST_STUDENT_DETAILS";

// Student Side Integrations
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_FREE_LOADING = "SET_IS_FREE_LOADING";
export const SET_STUDENT_FREE_TEST = "SET_STUDENT_FREE_TEST";
export const SET_STUDENT_FREE_TEST_UPCOMING = "SET_STUDENT_FREE_TEST_UPCOMING";
export const SET_STUDENT_ATTEMPTED_TEST = "SET_STUDENT_ATTEMPTED_TEST";
export const SET_STUDENT_TEST_DETAILS = "SET_STUDENT_TEST_DETAILS";
export const EMPTY_FREE_TEST = "EMPTY_FREE_TEST";

// Leaderboard

export const SET_LEADER_BOARD_DETAILS = "SET_LEADER_BOARD_DETAILS";


// Loader



// Drawer
export const SET_BOTTOM_DRAWER = "SET_BOTTOM_DRAWER"