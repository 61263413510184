import { act } from 'react-dom/cjs/react-dom-test-utils.production.min';
import { bindActionCreators } from 'redux';
import * as actionType from '../actions/actionTypes';
const initialState = {
	orgCode: "",
    orgId:'',
    orgName:'',
    testName:'',
    testId:'',
    mobile: "",
    countryCode: "+91",
    otp: "",
    name: '',
    verifyOtpError:false,
    verifyOtp:{},
    generateOtp:{},
    registerUser:{},
    registerUserError:false,
    sharedTest:{},
    isTestStarted:-2,
    // statusRedirection: localStorage.getItem("data") && false &&
    //                     JSON.parse(localStorage.getItem("data")).refreshToken &&
    //                     JSON.parse(localStorage.getItem("data")).token
    //                     ? "AlreadyLoggedIn"
    //                     : "AccountLogin",
    statusRedirection:"AccountLogin",
    wlToken: "",
    studentSideToken: "",
    tutorSideToken: ""  
};



const reducer = (state = initialState, action) => {
	switch (action.type) {
        case actionType.SET_ORG :{
            if(localStorage.getItem("loginData") && JSON.parse(localStorage.getItem("loginData")).orgCode && JSON.parse(localStorage.getItem("loginData")).orgCode==action.payload.data.organization.code){
                return{
                    ...state,
                    statusRedirection:"AlreadyLoggedIn",
                    testId:action.payload.data._id
                } 
            }
            else{ 
            return{
                ...state,
                orgCode:action.payload.data.organization.code,
                orgId:action.payload.data.organization.id,
                testName:action.payload.data.testName,
                testId: action.payload.data._id
            }
        }
        }
        case actionType.SET_ORG_NAME:{ console.log(action)
            return{
                ...state,
                orgName:action.payload.data.orgName
            }
        }
        case actionType.SET_COUNTRY_DETAILS :{
            return{
                ...state,
                ...action.payload.data
            }
        }
        case actionType.SET_MOBILE:
            return { ...state, mobile: action.payload };

        case actionType.SET_EMAIL:{
            return { ... state, email:action.payload}
        }    
        case actionType.GENERATE_OTP_SUCCESS:
            return{
                ...state,
                statusRedirection:"Otp",
                generateOtp: action.payload.generateOtp,
            };
        case actionType.SCREEN_REDIRECTION_STATUS: 
            return { ...state, statusRedirection: action.payload };

        case actionType.VERIFY_OTP_ERROR_CHANGE:
                return {
                        ...state,
                        verifyOtpError: action.payload
                    }

        case actionType.SET_OTP: 
             return { ...state, otp: action.payload };   

        case actionType.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOtp: action.payload.verifyOtp,
                verifyOtpError: action.payload.verifyOtpError,
                statusRedirection: "AlreadyLoggedIn"
            }; 

         case actionType.VERIFY_OTP_FAILURE:
            return {
                ...state,
                verifyOtpError: action.payload.verifyOtpError,
            }; 
        
        case actionType.REGISTER_USER_SUCCESS:
            return{
                ...state,
                registerUser: action.payload.registerUser,
                registerUserError: action.payload.registerUserError,
                statusRedirection: "AlreadyLoggedIn"
            }  
         case actionType.REGISTER_USER_FAILURE:
             return {
                ...state,
                registerUser: action.payload.registerUser,
                registerUserError: action.payload.registerUserError
             }  
           case actionType.SET_TEST_URL_FAIL:
               return{
                   ...state,
                sharedTest:{
                    ...state.sharedTest,
                    ...action.payload.data.sharedTest,
                    testURL:action.payload.data.sharedTest.testURL+`&testId=${action.payload.data.sharedTest.testId}`
                },
                isTestStarted:action.payload.isTestStarted,
                isStudent: action.payload.isStudent,
                region: action.payload.data.region 
               }  
            case actionType.SET_TEST_URL: console.log("actiond",action)
               return{
                   ...state,
                sharedTest:{
                    ...state.sharedTest,
                    testId:action.payload.data.testId,
                    testURL:action.payload.data.URL+`&testId=${action.payload.data.testId}`
                },
                isTestStarted:0,
                isStudent: action.payload.isStudent,
                region: action.payload.data.region
               }  
            case actionType.SET_STUDENT_SIDE_TOKEN: {
                return {
                    ...state,
                    wlToken: action.payload.wlToken,
                    studentSideToken: action.payload.studentSideToken
                }
            }  
            case actionType.SET_TUTOR_SIDE_TOKEN: {
                return {
                    ...state,
                    wlToken: action.payload.wlToken,
                    tutorSideToken: action.payload.tutorSideToken
                }
            }  
		default:
			return state;
	}
};

export default reducer;
