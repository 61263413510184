export const parseQueryString = (str) => {
  str = decodeURIComponent(str.replace(/^\?/, ""));
  let obj = {};
  if (str.indexOf("=") !== -1) {
    str.split("&").forEach((query) => {
      // if (isNaN(query.split("=")[1])) {
      // 	obj[query.split("=")[0]] = query.split("=")[1];
      // } else {
      // 	obj[query.split("=")[0]] = Number(query.split("=")[1]);
      // }
      obj[query.split("=")[0]] = query.split("=")[1];
    });
  } else {
    return {};
  }
  return obj;
};

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...arg) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...arg);
    }, delay);
  };
};


export const getTimeStringFirstLetters = secs => {
  let time_string;
  let hours = Math.floor(secs / (60 * 60));
  let minutes = Math.floor((secs - hours * 3600) / 60);
  let seconds = Math.ceil(secs - hours * 3600 - minutes * 60);
  if (seconds === 60) {
    minutes++;
    seconds = 0;
  }
  if (minutes === 60) {
    hours++;
    minutes = 0;
  }
  if (hours > 0) {
    if (minutes > 0) {
      time_string = `${hours}h ${minutes}m`;
    } else {
      time_string = `${hours}h`;
    }
  } else {
    time_string = `${minutes}m ${seconds > 0 ? +seconds + "s" : ""}`;
  }
  return time_string;
};


export function parseToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export function convertHMS(milliseconds) {
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


  return h + ':' + m + ':' + s;
}


export const checkForMobile = () => {
  let a = false;
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) {
    a = true;
  } else {
    a = false;
  }

  return a
}

export const disableDocumentScrolling = () => {
	document.scrollingElement.style.overflow = "hidden";
};

export const enableDocumentScrolling = () => {
	document.scrollingElement.style.overflow = "";
};


export function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  
}

export const getQueryParameterByName = (paramList, url) => {
	const passedUrl = url || window.location.href;
	if (typeof paramList !== "object" || !paramList.length) return {};
	const toReturn = {};
	paramList.forEach(param => {
		const passedParam = param.replace(/[[\]]/g, "\\$&");
		let regex;
		let value;
		if (passedParam == "studentTestId")
			regex = new RegExp(`[?&]${passedParam}(=([^&#]*)|&|#|$)$`);
		else regex = new RegExp(`[?&]${passedParam}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(passedUrl);
		if (!results) {
			value = null;
		} else if (!results[2]) {
			value = "";
		} else {
			value = decodeURIComponent(results[2].replace(/\+/g, " "));
		}
		toReturn[param] = value;
	});
	return toReturn;
};

export const hitDeeplinkExecutedV2 = (
  androidDeeplinkObj,
  iosDeeplinkObj,
  android = true,
  iOS = true
) => {
  console.log("deeplink called", androidDeeplinkObj);
  try {
    android && window?.mobile?.onDeeplinkExecutedV2(androidDeeplinkObj);
    iOS &&
      window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
        iosDeeplinkObj
      );
  } catch (err) {
    console.log("Something went wrong...");
  }
};

export const setFont = (/** @type {string} */ lang) => {
  const _lang = lang.toLocaleLowerCase();
  let font;

  if (_lang === "vi") font = "Be Vietnam Pro";
  else font = "Noto Sans";

  const linkHref = `https://fonts.googleapis.com/css2?family=${font.replace(
    / /g,
    "+"
  )}:wght@400;500;600;700&display=swap`;

  let stylesheetAlreadyExists = false;
  const allLinkStylesheets = document.querySelectorAll(
    'link[rel="stylesheet"]'
  );

  for (let i = 0; i < allLinkStylesheets.length; ++i) {
    if (allLinkStylesheets[i].href === linkHref) {
      stylesheetAlreadyExists = true;
      break;
    }
  }

  if (!stylesheetAlreadyExists) {
    // const prevFontFamily = getComputedStyle(
    //   document.documentElement
    // ).getPropertyValue("--font-family");
    // const fallbackFont = prevFontFamily && prevFontFamily.split(",")[1].trim();

    const link = document.createElement("link");
    link.href = linkHref;
    link.rel = "stylesheet";
    document.head.appendChild(link);
    link.onload = () => {
      // document.documentElement.style.setProperty(
      //   "--font-family",
      //   `'${font}', ${fallbackFont || "sans-serif"}`
      // );
      document.documentElement.style.setProperty("--font-family", font);
    };
  }
};
