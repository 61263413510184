import React, { Suspense } from 'react'
import {Route,Switch,Redirect} from 'react-router-dom';
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import ReduxToastr from "react-redux-toastr";
import { Trans, useTranslation } from "react-i18next";
const Login = React.lazy(()=>import('./Login/index'));
const LaunchTest = React.lazy(()=>import('./LaunchTest/LaunchTest'));
const FreeTest = React.lazy(()=>import('./FreeTestList'));
const TestDetails = React.lazy(()=>import('./TestDetails'));
const TestResult = React.lazy(()=>import('./StudentSide/TestResult'));
const Tests = React.lazy(()=>import('./StudentSide/Tests'));
const AttemptedTests = React.lazy(()=>import('./StudentSide/AttemptedTests'));
// const FreeTestCard = React.lazy(()=>import('./FreeTestList/FreeTestCard'));
const LeaderboardMobile = React.lazy(()=>import('./Leaderboard/LeaderboardMobile/LeaderboardMobile'));
const NotFoundTutor = React.lazy(()=>import ('./NotFoundTutor'));
const TestList = React.lazy(()=>import('./FreeTestList/testList'));
const Addtest =  React.lazy(()=>import('./FreeTestList/AddTest'));
function Routes(props) {
  const { t } = useTranslation();
    return (
        <Suspense fallback={<div>{t("Routes.indexjs.Loading", "Loading")}...</div>}>
            <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/attempt" component={LaunchTest}/>
            <Route exact path="/freetest" component={FreeTest}/>
            <Route exact path="/testlist" component={TestList} />
            <Route exact path="/addtest" component={Addtest}/>
            <Route exact path="/testDetails" component={TestDetails}/>
            <Route exact path="/tests" component={Tests}/>
            <Route exact path="/attempted" component={AttemptedTests}/>
            <Route exact path="/result" component={TestResult}/>
            <Route exact path="/leaderboard" component={LeaderboardMobile}/>
            <Route exact path="/notFound" component ={NotFoundTutor} />
            <Redirect to="/"/>
            </Switch>
            <ReduxToastr
				newestOnTop={false}
				preventDuplicates
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				timeOut={4000}
			/>
        </Suspense>
    )
}

export default Routes