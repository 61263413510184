import './App.css';
import Routes from './Routes/index'; 
import 'semantic-ui-css/semantic.min.css'
import { useEffect } from 'react';
import { getQueryParameterByName, setFont } from './util';
import axios from 'axios';
import i18n from './i18n';

function App() {

  useEffect(() => {
    const defaultLanguage = getQueryParameterByName(["defaultLanguage"], window.location.href)?.defaultLanguage

    if(defaultLanguage) {
      i18n.changeLanguage(defaultLanguage?.toUpperCase())
      setFont(defaultLanguage);
      axios.defaults.headers.common['accept-language'] = defaultLanguage?.toLowerCase()
    } else {
      i18n.changeLanguage("EN")
      setFont("EN");
      axios.defaults.headers.common['accept-language'] = "en"
    }
  },[])

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
