import * as actionType from '../actions/actionTypes';

const initialState = {
    freeTestState: "NonShared",
    searchTerm: "",
    isLoading: true,
    shareDetailsDrawer:false,
    shareOnWhatsApp:false,
    oldStartDate:'',
    testList: {
        count: 0,
        sharedtests: [],
        limit: 10,
        offset: 0
    },
    freeTest: {
        count: 0,
        sharedtests: [],
        limit: 10,
        offset: 0
    },
    testDetails: {
        _id: '',
        isFree: false,
        testName: '',
        testURL: '',
        startTime: '',
        endTime: ''
    },
    testStudentDetails: {
        hasMore: false,
        records: [],
        limit: 15,
        offset: 0
    },
    leaderBoardDetails: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_FREE_TEST_LOADING: 
            return {
                ...state,
                isLoading: action.payload
            }

        case actionType.SET_FREE_TEST_STATE:
            return {
                ...state,
                freeTestState: action.payload.data
            }

        case actionType.SET_TESTLIST:
            return {
                ...state,
                isLoading: false,
                freeTestState: "NonShared",
                testList: {
                    ...state.testList,
                    ...(action.payload.offset === 0) && {
                        offset: state.testList.limit,
                        sharedtests: action.payload.tests
                    },
                    ...(action.payload.offset !== 0) && {
                        offset: state.testList.offset + state.testList.limit,
                        sharedtests: [...state.testList.sharedtests, ...action.payload.tests],
                    },
                    count: action.payload.count,
                },
                searchTerm: "",
                ...(action.payload.search) && {
                    searchTerm: action.payload.search
                }
            }

        case actionType.SET_FREE_TESTLIST:
            return {
                ...state,
                isLoading: false,
                freeTest: {
                    ...state.freeTest,
                    ...(action.payload.offset === 0) && {
                        offset: state.freeTest.limit,
                        sharedtests: action.payload.sharedtests
                    },
                    ...(action.payload.offset !== 0) && {
                        offset: state.freeTest.offset + state.freeTest.limit,
                        sharedtests: [...state.freeTest.sharedtests, ...action.payload.sharedtests],
                    },
                    count: action.payload.count,
                },
                searchTerm: "",
                ...(action.payload.search) && {
                    searchTerm: action.payload.search
                },
                freeTestState: "Shared",
                region: action.payload.region
            }

        case actionType.SET_TEST_DETAILS:
            let newData = action.payload.data
            if(action.payload.bottomDrawer){
                return {
                    ...state,
                    testDetails: { ...newData },
                    shareDetailsDrawer:true,
                    oldStartDate:newData.startTime?newData.startTime:''
                }
            }
            else{
            return {
                ...state,
                testDetails: { ...newData },
                freeTestState: "TestDetails"
            }
            }
        case actionType.SET_DATE: console.log("changeObj",action.changeObj)
            return {
                ...state,
                testDetails: { ...state.testDetails, ...action.changeObj },
            }
        case actionType.SET_TEST_STUDENT_DETAILS: {
            return {
                ...state,
                isLoading:false,
                testStudentDetails: {
                    ...state.testStudentDetails,
                    ...action.payload,
                    ...(action.payload.offset === 0) && {
                        offset: state.testStudentDetails.limit,
                        records: action.payload.records
                    },
                    ...(action.payload.offset !== 0) && {
                        offset: state.testStudentDetails.offset + state.testStudentDetails.limit,
                        records: [...state.testStudentDetails.records, ...action.payload.records],
                    },
                    hasMore: (action.payload.records 
                                && action.payload.records.length === state.testStudentDetails.limit)
                                    ? true : false ,
                },
                region: action.payload.region
            }
        }
        case actionType.RESET_TEST_STUDENT_DETAILS: {
            return {
                ...state,
                testStudentDetails: {...initialState.testStudentDetails}
            }
        }
        case actionType.SET_LEADER_BOARD_DETAILS: {
            return {
                ...state,
                leaderBoardDetails: {
                    ...state.leaderBoardDetails,
                    ...action.payload
                }
            }
        }
        case actionType.SET_BOTTOM_DRAWER:{
            return{
                ...state,
                shareDetailsDrawer:action.flag
            }
        }
        case actionType.SET_WHATSAPP_DRAWER:{
            return{
                ...state,
                shareOnWhatsApp:action.flag
            }
        }
        default:
            return state
    }
}

export default reducer;