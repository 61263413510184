import * as CONSTANTS from '../actions/actionTypes';

const initialState = {
    searchTerm: "",
    isLoading: true,
    isFreeLoading: false,
    studentFreeTest: {
        count: 0,
        freeTests: [],
        limit: 10,
        offset: 0,
        searchTerm: ""
    },
    studentFreeTestUpcoming: {
        count: 0,
        freeTestsUpcoming: [],
        limit: 10,
        offset: 0,
        searchTerm: ""
    },
    studentAttemptedTest: {
        count: 0,
        attemptedTests: [],
        limit: 10,
        offset: 0,
        searchTerm: ""
    },
    studentTestDetails: {}
}

const setStudentFreeTest = (state, action) => {
    return {
        ...state,
        isFreeLoading: false,
        studentFreeTest: {
            ...state.studentFreeTest,
            ...(action.payload.offset === 0) && {
                offset: state.studentFreeTest.limit,
                freeTests: action.payload.freeTests
            },
            ...(action.payload.offset !== 0) && {
                offset: state.studentFreeTest.offset + state.studentFreeTest.limit,
                freeTests: [...state.studentFreeTest.freeTests, ...action.payload.freeTests],
            },
            count: action.payload.count,
            ...(action.payload.search) && {
                searchTerm: action.payload.search
            }
        },
        searchTerm: "",
        ...(action.payload.search) && {
            searchTerm: action.payload.search
        },
        region: action.payload.region
    }
}
const setStudentFreeTestUpcoming = (state, action) => {
    return {
        ...state,
        isFreeLoading: false,
        studentFreeTestUpcoming: {
            ...state.studentFreeTestUpcoming,
            ...(action.payload.offset === 0) && {
                offset: state.studentFreeTestUpcoming.limit,
                freeTestsUpcoming: action.payload.freeTests
            },
            ...(action.payload.offset !== 0) && {
                offset: state.studentFreeTestUpcoming.offset + state.studentFreeTestUpcoming.limit,
                freeTestsUpcoming: [...state.studentFreeTestUpcoming.freeTestsUpcoming, ...action.payload.freeTests],
            },
            count: action.payload.count,
            ...(action.payload.search) && {
                searchTerm: action.payload.search
            }
        },
        searchTerm: "",
        ...(action.payload.search) && {
            searchTerm: action.payload.search
        },
        region: action.payload.region
    }
}

const setStudentAttemptedTest = (state, action) => {
    return {
        ...state,
        isLoading: false,
        studentAttemptedTest: {
            ...state.studentAttemptedTest,
            ...(action.payload.offset === 0) && {
                offset: state.studentAttemptedTest.limit,
                attemptedTests: action.payload.attemptedTests
            },
            ...(action.payload.offset !== 0) && {
                offset: state.studentAttemptedTest.offset + state.studentAttemptedTest.limit,
                attemptedTests: [...state.studentAttemptedTest.attemptedTests, ...action.payload.attemptedTests],
            },
            count: action.payload.count,
            ...(action.payload.search) && {
                searchTerm: action.payload.search
            }
        },
        searchTerm: "",
        ...(action.payload.search) && {
            searchTerm: action.payload.search
        },
        region: action.payload.region
    }
}

const setStudentTestDetails = (state, action) => {
    return {
        ...state,
        isLoading: false,
        studentTestDetails: action.payload
    }
}

export const setIsLoading = (state,action) => {
    return {
        ...state,
        isLoading: action.payload
    }
}

export const setIsFreeLoading = (state,action) => {
    return {
        ...state,
        isFreeLoading: action.payload
    }
}
export const setFreeTestEmpty = (state,action) =>{
    return{
        ...state,
        studentFreeTest: {
            ...initialState.studentFreeTest
        }
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING : return setIsLoading(state,action);
        case CONSTANTS.SET_IS_FREE_LOADING : return setIsFreeLoading(state,action);
        case CONSTANTS.SET_STUDENT_FREE_TEST: return setStudentFreeTest(state, action);
        case CONSTANTS.SET_STUDENT_FREE_TEST_UPCOMING: return setStudentFreeTestUpcoming(state, action);
        case CONSTANTS.SET_STUDENT_ATTEMPTED_TEST: return setStudentAttemptedTest(state, action);
        case CONSTANTS.SET_STUDENT_TEST_DETAILS: return setStudentTestDetails(state, action);
        case CONSTANTS.EMPTY_FREE_TEST: return setFreeTestEmpty(state,action)
        default: return state
    }
}

export default reducer;